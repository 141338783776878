import clsx from 'clsx';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import * as cookie from 'cookie';
import { createClose, createMenu } from '@/assets/icons';
import { Button, Link, Logo, Modal, Text, TopSheet } from '@/components';
import { PreAuthModal, Search } from '@/features';
import { PageType } from '@/types/page';
import {
  BROWSE_ALL_ROUTE,
  FILE_REPORT_ROUTE,
  LEADERBOARD_ROUTE,
  getRouteForAddressSearch,
  getRouteForDomainSearch,
} from '@/types/routes';
import {
  buttonize,
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@/utils';
import isServer from '@/utils/isServer';
import { Desktop, UpToDesktop } from '@/utils/responsive';
import { useMe } from '@/hooks';
import { SecondTierNav } from '../second-tier-nav';
import AboutToggle from './AboutToggle';
import MyFeedToggle from './MyFeedToggle';

// Security banner cookie name
const SECURITY_BANNER_COOKIE_NAME = 'trm-accept-banner-security-notice';

// Function to check if the security banner has been accepted
const getIsSecurityBannerAccepted = (): boolean => {
  if (!isServer()) {
    const cookies = cookie.parse(document.cookie);
    return cookies[SECURITY_BANNER_COOKIE_NAME] === 'true';
  }
  return false;
};

export type HeaderProps = StyleProps & {
  /**
   * The page type the header is being rendered on.
   * Optional unless you need the header to change appearance
   * based on the rendered page
   */
  pageType?: PageType;
};

const ROOT = makeRootClassName('Header');
const el = makeElementClassNameFactory(ROOT);

const DEFAULT_PROPS = {} as const;

const CLOSE_ICON = createClose;
const HAMBURGER_ICON = createMenu;
const ROUTE_CHANGE_START_EVENT = 'routeChangeStart';

const Header = (props: HeaderProps): ReactElement => {
  const p = { ...DEFAULT_PROPS, ...props };
  const { events, asPath, push } = useRouter();
  const [sheetOpen, setSheetOpen] = useState(false);
  // default is true, only display modal if user hasn't accepted the notice
  const [showSecurityModal, setShowSecurityModal] = useState(false);
  const { me } = useMe();

  const inputRef = useRef<HTMLInputElement>(null);

  // Check if security banner cookie is accepted on mount
  useEffect(() => {
    const accepted = getIsSecurityBannerAccepted();
    
    // Only show the modal if user is logged in AND hasn't accepted the notice
    if (!accepted && me) {
      setShowSecurityModal(true);
    }
  }, [me]);

  // Function to accept the security notice
  const acceptSecurityNotice = useCallback(() => {
    // Set cookie to expire in 1 year
    const expiryDate = new Date();
    expiryDate.setFullYear(expiryDate.getFullYear() + 1);
    
    document.cookie = `${SECURITY_BANNER_COOKIE_NAME}=true; expires=${expiryDate.toUTCString()}; path=/`;
    
    setShowSecurityModal(false);
  }, []);

  const toggleSheet = () => setSheetOpen(!sheetOpen);

  useEffect(() => {
    const closeSheet = () => setSheetOpen(false);

    events.on(ROUTE_CHANGE_START_EVENT, closeSheet);
    return () => events.off(ROUTE_CHANGE_START_EVENT, closeSheet);
  }, [events]);

  const isAboutPage =
    p.pageType &&
    [
      PageType.ABOUT,
      PageType.FAQ,
      PageType.GLOSSARY,
      PageType.CONTACT,
    ].includes(p.pageType);

  const links = (
    <>
      <MyFeedToggle />
      <NextLink href={FILE_REPORT_ROUTE} passHref>
        <Link variant="white" isSelected={p.pageType === PageType.FILE_REPORT}>
          Report a Scam
        </Link>
      </NextLink>
      <NextLink href={LEADERBOARD_ROUTE} passHref>
        <Link variant="white" isSelected={p.pageType === PageType.LEADERBOARD}>
          Top Contributors
        </Link>
      </NextLink>
      <NextLink href={BROWSE_ALL_ROUTE} passHref>
        <Link variant="white" isSelected={p.pageType === PageType.BROWSE_ALL}>
          View Reports
        </Link>
      </NextLink>
      <AboutToggle isSelected={isAboutPage} />
    </>
  );

  const renderActions = (): ReactElement => {
    return (
      <>
        <UpToDesktop>
          <div
            {...buttonize(toggleSheet)}
            className={el`menu-button-tap-target`}
          >
            <Button
              size="custom"
              variant="text-white"
              startIcon={sheetOpen ? CLOSE_ICON : HAMBURGER_ICON}
              onPress={toggleSheet}
              startIconSize="custom"
              startIconClassName={el`menu-icon`}
              className={el`menu-button`}
              aria-label={
                sheetOpen ? 'Close navigation menu' : 'Open navigation menu'
              }
              aria-expanded={sheetOpen ? true : false}
            />
          </div>
          <TopSheet
            open={sheetOpen}
            onOpenChange={toggleSheet}
            className={el`mobile-sheet`}
          >
            <div className={el`search-wrapper`}>
              <Search
                variant="transparent"                            
                onSearchAddress={(address, chain) =>
                  push(getRouteForAddressSearch(address, chain))
                }
                onSearchDomain={(domain) =>
                  push(getRouteForDomainSearch(domain))
                }
                inputRef={inputRef}
              />
            </div>
            <section className={el`links`}>{links}</section>
            <SecondTierNav />
            <section>
              <PreAuthModal returnTo={asPath} />
            </section>
          </TopSheet>
        </UpToDesktop>
        <Desktop>
          <div className={el`links`}>
            {links}
            <PreAuthModal returnTo={asPath} />
          </div>
        </Desktop>
      </>
    );
  };

  return (
    <>
      <Modal.Root open={showSecurityModal} 
        onOpenChange={acceptSecurityNotice}
      >
        <Modal.Content>
          <Modal.Header 
            title="Security Notice" 
            hasCloseButton={true}
          />
          <div style={{ padding: '16px 0 48px' }}>
            <Text>March 6, 2025 — 1:00PM ET — We recently took the
              Chainabuse website offline for 15 hours after 
              discovering a potential security issue, namely, that a 
              third party could exploit a technical vulnerability to 
              see the contact email addresses of Chainabuse users 
              who submitted public reports to the platform. The 
              vulnerability did not allow access to users’ actual 
              names or any other sensitive information. We took 
              immediate steps to fix the issue, and have no evidence 
              at this time suggesting that any data was misused. We 
              have implemented additional security measures to 
              prevent this from happening again. Protecting the 
              information entrusted to us remains our top priority, 
              and we will take our learnings to continuously harden 
              our controls. If you have any questions or concerns,
              please contact us at <a href="mailto:security@chainabuse.com" className="text-blue-500 hover:underline">security@chainabuse.com</a>. </Text>
            <br />
            <Text isHeavy>Please note that Chainabuse will never
               email or message you asking you to click any links or
               requesting funds. Please stay vigilant, and if you
               receive such a request, ignore it and report it
               immediately.
            </Text>
          </div>
        </Modal.Content>
      </Modal.Root>
      
      <header
        className={clsx(ROOT, {
          'is-home': p.pageType === PageType.HOME,
          'is-top-sheet-open': sheetOpen,
        })}
      >
        <NextLink href="/" passHref>
          <a>
            <Logo variant="light" />
          </a>
        </NextLink>
        {renderActions()}
      </header>
    </>
  );
};
export default Header;
